<template>
	<button type="button" :disabled="disabled || loading" :class="buttonStyling" @click="emit('click')">
		<div v-if="loading" class="mr-2 -ml-1.5 spinner" />
		<slot></slot>
		<span v-if="label">{{ label }}</span>
	</button>
</template>

<script setup lang="ts">
	import { computed } from 'vue';
	import { KeyValuePair } from '@/types/app';

	interface Props {
		classes?: KeyValuePair | string;
		label?: string;
		disabled?: boolean;
		loading?: boolean;
		border?: boolean;
		color?: string;
	}

	const props = withDefaults(defineProps<Props>(), {
		classes: 'inline-flex w-full justify-center items-center rounded-md px-4 py-2 text-base font-medium shadow-sm focus:outline-none sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed',
		label: '',
		disabled: false,
		loading: false,
		border: true,
		color: undefined,
	});

	// Type guard to check if an object is of type KeyValuePair
	function isKeyValuePair(obj: unknown): obj is KeyValuePair {
		return typeof obj === 'object' && obj !== null && 'key' in obj && 'value' in obj;
	}
	function stringToObject(str: string): KeyValuePair {
		const words = str.split(' ');
		const result = {} as KeyValuePair;
		words.forEach((word: string) => {
			result[word] = true;
		});
		return result;
	}

	const buttonStyling = computed<KeyValuePair>(() => {
		let classesProvided;
		if (!isKeyValuePair(props.classes)) {
			classesProvided = stringToObject(props.classes);
		} else {
			classesProvided = props.classes;
		}
		let buttonClasses = {
			...(classesProvided ?? {}),
		};
		if (props.color && props.color !== 'white') {
			buttonClasses = {
				...buttonClasses,
				...{
					border: props.border,
					'border-transparent': props.border,
					'text-white': true,
					['bg-' + props.color + '-600']: true,
					['hover:bg-' + props.color + '-700']: true,
				},
			};
		} else if (props.color == 'white') {
			buttonClasses = {
				...buttonClasses,
				...{
					border: props.border,
					'border-gray-300': props.border,
					'text-gray-700': true,
					'bg-white': true,
					'hover:bg-gray-50': true,
				},
			};
		}
		return buttonClasses;
	});

	const emit = defineEmits<{
		(e: 'click'): void;
	}>();
</script>
